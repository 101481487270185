import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","attach":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i,attrs:{"dense":""},on:{"click":item.handler}},[_c(VListItemIcon,{staticClass:"mr-4"},[_c(VIcon,{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }