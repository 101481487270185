<template>
	<v-chip class="mx-2" style="background-color: #afb1b8" text-color="white">
		<template v-slot:default>
			<div class="d-flex align-center">
				<div>attachment</div>
				<a
					:download="`attachment_${Date.now()}`"
					:href="data.attachment"
					style="background: none; color: transparent;"
				>
					<v-btn icon small class="ml-2">
						<v-icon color="white">mdi-download</v-icon>
					</v-btn>
				</a>
			</div>
		</template>
	</v-chip>
</template>

<script>
export default {
	name: "AttachmentChip",
	props: {
		data: {
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style scoped></style>
