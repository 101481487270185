<template>
	<v-form ref="commentForm" class="d-flex full-width" @submit.prevent="">
		<UserAvatar :user-data="state.userData" />

		<div class="d-flex flex-column flex-grow-1">
			<v-textarea
				v-model="state.message"
				autocomplete="off"
				:label="label"
				class="ml-4"
				dense
				hide-details="auto"
				outlined
				auto-grow
				rows="1"
				append-outer-icon="mdi-send"
				@click:append-outer="handleSendClick"
				:rules="state.commentRules"
				validate-on-blur
			>
				<template v-slot:append>
					<v-file-input
						v-model="state.attachFile"
						class="comment-file-input"
						dense
						hide-details
						@click.stop
					/>
				</template>
			</v-textarea>
			<div v-if="state.attachFile">
				<v-chip
					class="ma-2"
					close
					color="grey"
					text-color="white"
					@click:close="clearAttach"
				>
					{{ state.attachFile.name }}
				</v-chip>
			</div>
		</div>
	</v-form>
</template>

<script>
import { computed, reactive, watch } from "@vue/composition-api";
import ForumService from "../../services/forum.service";
import UserAvatar from "../profile/UserAvatar";
import { toBase64 } from "../../utilities/general.utilities";

export default {
	name: "AddComment",
	components: { UserAvatar },
	props: {
		label: {
			type: String,
			default: "Shkruaj diçka..."
		},
		type: {
			type: String,
			default: "comment"
		},
		postId: {
			type: String
		},
		forumType: {
			type: String
		}
	},
	setup(props, { root, emit, refs }) {
		const state = reactive({
			userData: computed(() => root.$store.getters["user/getCurrentUserData"]),
			commentRules: [
				v => !!v.trim() || "Komenti nuk mund të jetë bosh!",
				v =>
					v?.length <= 1000 ||
					"Komenti nuk mund të ketë më shumë se 1000 karaktere!"
			],

			message: "",
			attach: null,
			attachFile: null,

			fileSizeLimit: 5242880
		});

		watch(
			() => state.attachFile,
			async file => {
				if (file) {
					console.log({ file });

					if (file.size > state.fileSizeLimit) {
						alert("Maksimumi i lejuar është 5MB");
						state.attachFile = null;
						return;
					}

					state.attach = await toBase64(file);
				}
			}
		);

		async function addComment() {
			if (!refs["commentForm"]?.validate()) return;

			try {
				await ForumService.createComment({
					text: state.message,
					postId: props.postId,
					attachment: state.attach
				});
				state.message = "";
				clearAttach();
				emit("new-comment");
			} catch (err) {
				console.log(err);
			}
		}

		async function addPost() {
			if (!refs["commentForm"]?.validate()) return;

			try {
				const routeParams = root.$route.params;
				await ForumService.createPost({
					text: state.message,
					classroomId: routeParams.classroomId,
					forumType: props.forumType,
					attachment: state.attach
				});
				state.message = "";
				clearAttach();
				emit("new-post");
			} catch (err) {
				console.log(err);
			}
		}

		function handleSendClick() {
			switch (props.type) {
				case "post":
					addPost();
					break;
				case "comment":
					addComment();
					break;
			}
		}

		const clearAttach = () => {
			state.attachFile = null;
			state.attach = undefined;
		};

		return {
			state,

			handleSendClick,
			addComment,
			addPost,

			clearAttach
		};
	}
};
</script>

<style lang="scss" scoped>
.v-input::v-deep {
	.v-text-field__details {
		margin-top: 6px;
		margin-bottom: 0;
	}
}

.comment-file-input::v-deep {
	margin: 0;

	.v-input__control {
		display: none;
	}

	.v-input__prepend-outer {
		margin: 0 !important;
	}
}
</style>
