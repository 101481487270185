<template>
	<v-card class="bg-card" outlined>
		<div class="d-flex full-width align-center pa-4">
			<UserAvatar :user-data="post.createdBy" />

			<div class="d-flex flex-column full-width ml-4">
				<div class="d-flex align-center justify-space-between">
					<div class="user-fullName">
						{{ post.createdBy.firstName + " " + post.createdBy.lastName }}
					</div>
					<div class="d-flex align-center">
						<div @click="toggleDateView" class="comment-date">
							{{ getDate(post.createdAt) }}
						</div>
						<!-- Comment actions -->
						<div
							v-if="isGrantedAccess('deleteAny', 'comment')"
							class="ml-2 mr-n2"
						>
							<ActionsButton
								:item-id="post.id"
								@delete-item="handlePostDelete"
							/>
						</div>
					</div>
				</div>
				<!--				<div class="subject">Biologji, Klasa 7</div>-->
			</div>
		</div>

		<v-card-text class="text--primary pt-0 pb-2">
			<div class="text-pre-line">
				{{ post.text }}
			</div>
		</v-card-text>

		<!-- Attachment -->
		<div v-if="post.attachment" class="d-flex justify-start mx-1 mb-2">
			<AttachmentChip :data="post" />
		</div>

		<v-divider />

		<div :ref="`${post.id}-comments`" class="comment-wrapper">
			<div v-for="comment in post.comments" :key="comment.id">
				<div class="d-flex align-center px-4 my-2">
					<UserAvatar :user-data="comment.createdBy" />
					<div class="d-flex flex-column ml-4 flex-grow-1">
						<div class="post-author">
							{{
								`${comment.createdBy.firstName} ${comment.createdBy.lastName}`
							}}
						</div>
						<div class="post-comment">{{ comment.text }}</div>
					</div>
					<div @click="toggleDateView" class="comment-date ml-2 text-no-wrap">
						{{ getDate(comment.createdAt) }}
					</div>
					<!-- Comment actions -->
					<div
						v-if="isGrantedAccess('deleteAny', 'comment')"
						class="ml-2 mr-n2"
					>
						<ActionsButton
							:item-id="comment.id"
							@delete-item="handleCommentDelete"
						/>
					</div>
				</div>
				<div v-if="comment.attachment" class="d-flex justify-end mb-2">
					<AttachmentChip :data="comment" />
				</div>

				<v-divider inset />
			</div>
		</div>

		<v-card-actions class="pa-4">
			<AddComment
				@new-comment="handleNewComment"
				:post-id="post.id"
				label="Shto një koment..."
			/>
		</v-card-actions>
	</v-card>
</template>

<script>
import AddComment from "./AddComment";
import { onMounted, reactive, watch } from "@vue/composition-api";
import { formatDate, timeSince } from "../../utilities/general.utilities";
import UserAvatar from "../profile/UserAvatar";
import AttachmentChip from "./AttachmentChip";
import ActionsButton from "./ActionsButton";
import ForumService from "../../services/forum.service";

export default {
	name: "Post",
	components: { ActionsButton, AttachmentChip, UserAvatar, AddComment },
	props: {
		post: {
			type: Object,
			default: () => {},
		},
	},
	setup(props, { root, emit, refs }) {
		const state = reactive({
			dateMode: "normal",
		});

		onMounted(() => {
			scrollToLatest();
		});

		function scrollToLatest() {
			root.$nextTick(() => {
				const el = refs[`${props.post.id}-comments`];
				if (el) {
					el.scrollTop = el.scrollHeight - el.clientHeight;
				}
			});
		}

		watch(
			() => props.post.comments,
			() => {
				scrollToLatest();
			}
		);

		const handleNewComment = () => {
			emit("has-updates");
		};

		const getDate = (date) => {
			if (state.dateMode === "normal") {
				return formatDate(date);
			} else {
				return timeSince(date) + " më parë";
			}
		};

		const toggleDateView = () => {
			if (state.dateMode === "normal") {
				state.dateMode = "timeSince";
			} else {
				state.dateMode = "normal";
			}
		};

		const handleCommentDelete = async (commentId) => {
			await ForumService.deleteComment({
				commentId,
				classroomId: props.post.classRoom,
			});
		};

		const handlePostDelete = async (postId) => {
			await ForumService.deletePost({
				postId,
				classroomId: props.post.classRoom,
			});
		};

		return {
			state,

			getDate,
			toggleDateView,
			handleNewComment,

			handleCommentDelete,
			handlePostDelete,
		};
	},
};
</script>

<style lang="scss" scoped>
.comment-wrapper {
	max-height: 400px;
	overflow-y: auto;
}

.comment-date {
	font-size: 0.8rem;
	font-weight: 500;
	opacity: 0.6;
}

.subject {
	font-size: 0.875rem;
	font-weight: 500;
	opacity: 0.6;
}

.post-author {
	font-weight: 500;
	opacity: 0.8;
	font-size: 0.875rem;
}

.post-comment {
	font-size: 0.875rem;
	white-space: pre-wrap;
}
</style>
