import store from "../store";

const ForumService = {
	async fetchPosts({ classroomId, forumType }) {
		await store.dispatch("forum/fetchPosts", { classroomId, forumType });
	},

	getPosts() {
		return store.getters["forum/getPosts"];
	},

	async createPost({ classroomId, forumType, text, attachment = undefined }) {
		await store.dispatch("forum/createPost", {
			classroomId,
			forumType,
			text: text?.toString().trim(),
			attachment,
		});
	},

	async createComment({ postId, text, attachment = undefined }) {
		await store.dispatch("forum/createComment", {
			postId,
			text: text?.toString().trim(),
			attachment,
		});
	},

	async deletePost({ postId, classroomId }) {
		await store.dispatch("forum/deletePost", { postId, classroomId });
	},

	async deleteComment({ commentId, classroomId }) {
		await store.dispatch("forum/deleteComment", { commentId, classroomId });
	},
};

export default ForumService;
