<template>
	<v-avatar size="40" color="primary" class="justify-center">
		<img v-if="userData.avatar" :src="userData.avatar" alt="User Logo" />
		<span v-else class="white--text text-h5">
			{{ getInitials(`${userData.firstName} ${userData.lastName}`) }}
		</span>
	</v-avatar>
</template>

<script>
import { getInitials } from "../../utilities/general.utilities";

export default {
	name: "UserAvatar",
	props: {
		userData: {
			type: Object,
			default: () => {}
		}
	},
	setup() {
		return {
			getInitials
		};
	}
};
</script>

<style scoped></style>
