<template>
	<v-menu bottom left offset-y attach="">
		<template v-slot:activator="{ on, attrs }">
			<v-btn icon v-bind="attrs" v-on="on">
				<v-icon>mdi-dots-vertical</v-icon>
			</v-btn>
		</template>

		<v-list dense>
			<v-list-item
				v-for="(item, i) in items"
				:key="i"
				dense
				@click="item.handler"
			>
				<v-list-item-icon class="mr-4">
					<v-icon :color="item.color">{{ item.icon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-title>
					{{ item.title }}
				</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	name: "ActionsButton",
	props: {
		itemId: {
			type: String,
			required: true,
		},
	},
	emits: ["delete-item"],
	setup(props, { emit }) {
		const items = [
			{
				title: "Fshij",
				icon: "mdi-trash-can-outline",
				color: "negative",
				handler: () => emit("delete-item", props.itemId),
			},
		];

		return {
			items,
		};
	},
};
</script>

<style lang="scss" scoped></style>
